import React, { useState } from 'react';


import appstore from '../../assets/img/applestore.svg';
import googlestore from '../../assets/img/googlestore.svg';
import { Link } from 'react-router-dom';

export default function Modal({ modal, setModal }) {


    const changeHandler = () => nulll

    return (

        <section className="modal" style={{ display: modal ? "block" : "none", background: "#00587edf" }}>

            <div className="modal--inner" onClick={() => setModal(false)}  >
                <div className="modal--content modal--store">
                    <div className="qr_container">

                        {/* hamburger */}
                        <div className="modal--cancel modal--set" onClick={() => setModal(false)}>
                            <span></span>
                            <span></span>
                        </div>
                        {/* end of hamburger */}

                        <div className="form">
                            <div className='store-inner'>
                                <h3 className="qr-info">Coming soon!</h3>
                                <div className='row store-img'>
                                    <a rel="noopener noreferrer" href="#" className='col-md-4' >
                                        <img src={appstore} alt="WOTA - Home" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}
